<template>
  <div>
    <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
      </el-form-item>
      <el-form-item>
        <el-select clearable filterable v-model="queryParams.type" placeholder="请选择类型" @change="resetPageAndRefresh">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.code" :value="item.id3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.content" clearable placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <div v-if="suggestTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="suggestList" :total="suggestTotal" :loading="tLoading" v-if="suggestTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column sortable="custom" prop="extend.idTypeName" show-overflow-tooltip label="类型"></el-table-column>
        <el-table-column sortable="custom" prop="content" show-overflow-tooltip label="内容"></el-table-column>
        <el-table-column sortable="custom" prop="extend.userName" show-overflow-tooltip label="发布人"></el-table-column>
        <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校"></el-table-column>
        <el-table-column sortable="custom" prop="extend.mobile" show-overflow-tooltip label="手机号"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:cxzj:lead')"  size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="审核"></el-button>
            <el-button  v-if="OA.includes('jcxx:cxzj:del')"  size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>
    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="类型：" prop="extend.idTypeName">
          <span v-text="details.extend.idTypeName"></span>
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <span v-text="details.content"></span>
        </el-form-item>
        <el-form-item label="图片：" prop="imgList">
          <span v-for="(item,index) in details.imgList" :key="index">
            <el-image style="width: 100px; height: 100px" :src="item"></el-image>
          </span>
        </el-form-item>
        <el-form-item label="发布人：" prop="extend.userName">
          <span v-text="details.extend.userName"></span>
        </el-form-item>
        <el-form-item label="学校：" prop="extend.schoolName">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="手机号：" prop="extend.mobile">
          <span v-text="details.extend.mobile"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'suggestions',
  components: {
    PagedTable,
    AreaTree
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      typeOptions:[],
      checkVisible: false,
      queryParams: {
        pageNum:1,
        pageSize:10,
        type: '',
      },
      details:{
        extend: {},
        id: '',
        schoolId: '',
        userId: '',
        module: '',
        ideaType: '',
        title: '',
        content: '',
        srcType: null,
        status: '',
        imgList: []
      },
      
      pageApi:'adviseList',
      loadApi: "adviseLoad",
      deleteApi:'adviseDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("suggest", ["suggestList", "suggestTotal"]),
  },
  methods: {
    ...mapActions("creative",["dictSelectAll"]),
    ...mapActions("suggest",["adviseList", "adviseLoad", "adviseFinish", "adviseDelete"]),
    handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
        });
        this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    log(this.details)
    this.refresh()
    this.typeOptions = await this.dictSelectAll({pid:106001003}) 
  }
};
</script>
<style lang="scss" scoped>
.w450 { width: 450px; }
.inlineForm { 
  .el-image { 
    width: 200px; 
  } 
}
.select{
  .year {
    /deep/.el-input__inner {  
      background: url('../../assets/images/he/operate/area.svg') no-repeat; 
      background-size: 12px 14px;  
      background-position: 8px 7px; 
      padding: 0 0 0 30px; 
      box-sizing: border-box;
      font-size: 14px;
    }
  }
}
</style>